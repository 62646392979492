<template>
    <div class="page-bulk-previous-searches">
        <app-table
            class="searches-table"

            :sort-value="filter.sort"
            :sort-options="sort"
            @sort-change="onSortChange"

            :cols="cols"
            :rows="searches"

            :clickable="true"
            @row-click="onSearchDetails"
            @row-click-mobile="onSearchDetails"

            :loading="loading"

            @remove-search="onRemoveSearch"
        />

        <app-pagination
            v-if="pagination.total"
            v-model="pagination.page"

            :total="pagination.total"
            :limit="pagination.limit"
            :page-range="pagination.range"

            @change="onPageChange"
        />

        <app-dialog-bulk-search-remove
            v-if="dialogs.remove.show"
            v-model="dialogs.remove.show"

            :uuid="dialogs.remove.uuid"

            @removed="onSearchRemoved"
            @closes="onSearchRemoveClosed"
        ></app-dialog-bulk-search-remove>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'

import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import appDialogBulkSearchRemove from './dialogs/app-dialog-bulk-search-remove'

export default {
    props: {
        search: { type: Object, default: () => ({ word: '', option: null })},
    },

    components: {
        baseLayout,
        
        appTable,
        appPagination,

        appDialogBulkSearchRemove,
    },

    data() {
        return {
            searches_list: [],

            filter: {
                word: '',
                previous: '',
                sort: {
                    field: 'CreatedAtNanos',
                    order: 'desc',
                },
            },

            sort: [
                { field: 'Description',           title: 'Description'         },
                { field: 'CountTotal',            title: 'Num Total'           },
                { field: 'CountFoundAddress',     title: 'Num Found'           },
                { field: 'CountMatchedAddress',   title: 'Num Matched'         },
                { field: 'CountPrequalDone',      title: 'Num PreQual'         },
                { field: 'CountHaveService',      title: 'Num Services'        },
            ],

            cols: [
                { key: 'Description',         title: 'Description',         sort: { field: 'Description'         }, highlight: true,        },
                { key: 'CountTotal',          title: 'Num Total',           sort: { field: 'CountTotal'          }                          },
                { key: 'CountFoundAddress',   title: 'Num Searched',        sort: { field: 'CountFoundAddress'   }                          },
                // { key: 'CountMatchedAddress', title: 'Num Service Checked', sort: { field: 'CountMatchedAddress' }                          },
                { key: 'CountPrequalDone',    title: 'Num Completed',       sort: { field: 'CountPrequalDone'    }                          },
                { key: 'CountHaveService',    title: 'Num Services',        sort: { field: 'CountHaveService'    }                          },
                { key: 'remove',                                            action: 'remove-search', icon: 'remove', behavior: 'detached' },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
                remove: {
                    show: false,
                    uuid: null,
                },
            },

            loading: false,
        }
    },

    methods: {
        init(to, from) {
            const getSearches = () => { this.getSearches(to.params.page) }

            if (from.name && /^bulk(-|$)/i.test(from.name)) {
                this.loadSearchesFilter().then(getSearches).catch(getSearches)
            } else {
                this.$store.dispatch('clearBulkFilter').then(getSearches).catch(getSearches)
            }
        },

        loadSearchesFilter() {
            return this.$store.dispatch('loadBulkFilter').then(loaded_filter => {
                let filter = {}

                for (const key in this.filter) {
                    filter[key] = key in loaded_filter
                        ? typeof this.filter[key] == 'string'
                            ? loaded_filter[key]
                            : {...this.filter[key], ...loaded_filter[key]}
                        : this.filter[key]
                }
                
                this.filter = filter
            }).catch(error => Promise.resolve(error))
        },

        getSearches(page, filter) {
            // console.group('getSearches(page) {')
            // console.log('page', page)

            this.loading = true

            filter = {
                ...this.filter,
                ...this.search,
                ...filter
            }
            // console.warn('filter', {...filter})

            let params = {
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize': this.pagination.limit,
                'SearchOptions.SortBy': filter.sort.field,
                'SearchOptions.SortDesc': filter.sort.order === 'desc' ? 1 : 0,
            }

            const word = filter.word.trim()
            // console.log('word', word)

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                    'SearchOptions.PageNumber': word !== filter.previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.filter.word = word
                this.filter.previous = word
            }
            // console.log('params', {...params})

            if (this.current_spid != this.spid) {
                params.SPID = this.current_spid
            }
            
            this.$store.dispatch('api_bulkprequal/GetBulkPreQualJobsPaginated', params).then(response => {
                this.searches_list = response.BulkPreQualJobs

                const { DisplayPageList, PageNumber, TotalItemCount } = response.PageInfo

                this.pagination.page = PageNumber
                this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                }

                this.loading = false
            }).catch(error => {
                console.error(error)

                this.searches = []

                this.loading = false
            })

            // console.groupEnd()
        },



        /**
         * Filtering
         */
        onSearch(search) {
            // console.group('onSearch(search) {')
            // console.log('search', {...search})
            // console.log('search.word.trim()', search.word.trim())
            // console.log('this.filter.word.trim()', this.filter.word.trim())

            if (search.word.trim() != this.filter.word.trim()) {
                this.getSearches(this.pagination.page, { word: search.word })
            }

            // console.groupEnd()
        },
        
        onSortChange(sort) {
            this.filter.sort = sort
            this.getSearches()
        },

        onPageChange(page) {
            this.getSearches(page)
        },



        /**
         * Removing
         */
        onRemoveSearch(search) {
            this.dialogs.remove.show = true
            this.dialogs.remove.uuid = search.uuid
        },

        onSearchRemoved() {
            this.getSearches()

            this.dialogs.remove.show = false
            this.dialogs.remove.uuid = null
        },

        onSearchRemoveClosed() {
            this.dialogs.remove.show = false
            this.dialogs.remove.uuid = null
        },



        /**
         * Details
         */
        onSearchDetails(search) {
            this.$router.push({ name: 'bulk-search', params: { uuid: search.uuid } })
        },
    },

    computed: {
        ...mapGetters([
            'spid',
            'current_spid',
        ]),

        searches() {
            return this.searches_list.map(search => ({
                ...search,
                uuid:      search.UUID,
            }))
        },
    },

    beforeRouteEnter(to, from, next) {
        next(route => { route.init( to, from ) })
    },
}
</script>

<style lang="scss">
.page-bulk-previous-searches {
    .searches-table {
        @include table-cols-width((200px, 180px, 180px, 180px, 180px, 48px), true);

        .rows {
            .row {
                .col {
                    &:nth-child(6) {
                        justify-content: flex-end;
                    }
                }
            }
        }
    }

    .app-pagination {
        margin-top: 30px;
    }
}


@media (max-width: $tablet-size) {
    .page-bulk-previous-searches {
        .searches-table {
            @include table-cols-width((150px, 100px, 110px, 150px, 115px, 48px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-bulk-previous-searches {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .searches-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((95px, 150px, 24px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>